import { default as _91pagePath_93MzbHuNuiYVMeta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/[pagePath].vue?macro=true";
import { default as _91slug_93AaXZmJVzKZMeta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/category/[slug].vue?macro=true";
import { default as indexzXB2QqJzdFMeta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/index.vue?macro=true";
import { default as infoVrPxVpOsp8Meta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/info.vue?macro=true";
import { default as _91slug_93qRTGSI5q0uMeta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/post/[slug].vue?macro=true";
import { default as previewVKSiRJc142Meta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/preview.vue?macro=true";
import { default as test3dZWNCah7WMeta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/test.vue?macro=true";
import { default as work3fl7e0iphmMeta } from "/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/work.vue?macro=true";
export default [
  {
    name: _91pagePath_93MzbHuNuiYVMeta?.name ?? "pagePath___de",
    path: _91pagePath_93MzbHuNuiYVMeta?.path ?? "/:pagePath()",
    meta: _91pagePath_93MzbHuNuiYVMeta || {},
    alias: _91pagePath_93MzbHuNuiYVMeta?.alias || [],
    redirect: _91pagePath_93MzbHuNuiYVMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/[pagePath].vue").then(m => m.default || m)
  },
  {
    name: _91pagePath_93MzbHuNuiYVMeta?.name ?? "pagePath___en",
    path: _91pagePath_93MzbHuNuiYVMeta?.path ?? "/en/:pagePath()",
    meta: _91pagePath_93MzbHuNuiYVMeta || {},
    alias: _91pagePath_93MzbHuNuiYVMeta?.alias || [],
    redirect: _91pagePath_93MzbHuNuiYVMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/[pagePath].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AaXZmJVzKZMeta?.name ?? "category-slug___de",
    path: _91slug_93AaXZmJVzKZMeta?.path ?? "/category/:slug()",
    meta: _91slug_93AaXZmJVzKZMeta || {},
    alias: _91slug_93AaXZmJVzKZMeta?.alias || [],
    redirect: _91slug_93AaXZmJVzKZMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AaXZmJVzKZMeta?.name ?? "category-slug___en",
    path: _91slug_93AaXZmJVzKZMeta?.path ?? "/en/category/:slug()",
    meta: _91slug_93AaXZmJVzKZMeta || {},
    alias: _91slug_93AaXZmJVzKZMeta?.alias || [],
    redirect: _91slug_93AaXZmJVzKZMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexzXB2QqJzdFMeta?.name ?? "index___de",
    path: indexzXB2QqJzdFMeta?.path ?? "/",
    meta: indexzXB2QqJzdFMeta || {},
    alias: indexzXB2QqJzdFMeta?.alias || [],
    redirect: indexzXB2QqJzdFMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexzXB2QqJzdFMeta?.name ?? "index___en",
    path: indexzXB2QqJzdFMeta?.path ?? "/en",
    meta: indexzXB2QqJzdFMeta || {},
    alias: indexzXB2QqJzdFMeta?.alias || [],
    redirect: indexzXB2QqJzdFMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infoVrPxVpOsp8Meta?.name ?? "info___de",
    path: infoVrPxVpOsp8Meta?.path ?? "/info",
    meta: infoVrPxVpOsp8Meta || {},
    alias: infoVrPxVpOsp8Meta?.alias || [],
    redirect: infoVrPxVpOsp8Meta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/info.vue").then(m => m.default || m)
  },
  {
    name: infoVrPxVpOsp8Meta?.name ?? "info___en",
    path: infoVrPxVpOsp8Meta?.path ?? "/en/info",
    meta: infoVrPxVpOsp8Meta || {},
    alias: infoVrPxVpOsp8Meta?.alias || [],
    redirect: infoVrPxVpOsp8Meta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/info.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qRTGSI5q0uMeta?.name ?? "post-slug___de",
    path: _91slug_93qRTGSI5q0uMeta?.path ?? "/post/:slug()",
    meta: _91slug_93qRTGSI5q0uMeta || {},
    alias: _91slug_93qRTGSI5q0uMeta?.alias || [],
    redirect: _91slug_93qRTGSI5q0uMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/post/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qRTGSI5q0uMeta?.name ?? "post-slug___en",
    path: _91slug_93qRTGSI5q0uMeta?.path ?? "/en/post/:slug()",
    meta: _91slug_93qRTGSI5q0uMeta || {},
    alias: _91slug_93qRTGSI5q0uMeta?.alias || [],
    redirect: _91slug_93qRTGSI5q0uMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/post/[slug].vue").then(m => m.default || m)
  },
  {
    name: previewVKSiRJc142Meta?.name ?? "preview___de",
    path: previewVKSiRJc142Meta?.path ?? "/preview",
    meta: previewVKSiRJc142Meta || {},
    alias: previewVKSiRJc142Meta?.alias || [],
    redirect: previewVKSiRJc142Meta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewVKSiRJc142Meta?.name ?? "preview___en",
    path: previewVKSiRJc142Meta?.path ?? "/en/preview",
    meta: previewVKSiRJc142Meta || {},
    alias: previewVKSiRJc142Meta?.alias || [],
    redirect: previewVKSiRJc142Meta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: test3dZWNCah7WMeta?.name ?? "test___de",
    path: test3dZWNCah7WMeta?.path ?? "/test",
    meta: test3dZWNCah7WMeta || {},
    alias: test3dZWNCah7WMeta?.alias || [],
    redirect: test3dZWNCah7WMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/test.vue").then(m => m.default || m)
  },
  {
    name: test3dZWNCah7WMeta?.name ?? "test___en",
    path: test3dZWNCah7WMeta?.path ?? "/en/test",
    meta: test3dZWNCah7WMeta || {},
    alias: test3dZWNCah7WMeta?.alias || [],
    redirect: test3dZWNCah7WMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/test.vue").then(m => m.default || m)
  },
  {
    name: work3fl7e0iphmMeta?.name ?? "work___de",
    path: work3fl7e0iphmMeta?.path ?? "/work",
    meta: work3fl7e0iphmMeta || {},
    alias: work3fl7e0iphmMeta?.alias || [],
    redirect: work3fl7e0iphmMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/work.vue").then(m => m.default || m)
  },
  {
    name: work3fl7e0iphmMeta?.name ?? "work___en",
    path: work3fl7e0iphmMeta?.path ?? "/en/work",
    meta: work3fl7e0iphmMeta || {},
    alias: work3fl7e0iphmMeta?.alias || [],
    redirect: work3fl7e0iphmMeta?.redirect,
    component: () => import("/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/pages/work.vue").then(m => m.default || m)
  }
]