import { defineNuxtPlugin } from '#app/nuxt'
import { LazyStrapiBlocksTextHeading1Node, LazyStrapiBlocksTextHeading2Node, LazyStrapiBlocksTextHeading3Node, LazyStrapiBlocksTextHeading4Node, LazyStrapiBlocksTextHeading5Node, LazyStrapiBlocksTextHeading6Node, LazyStrapiBlocksTextListItemInlineNode, LazyStrapiBlocksTextOrderedListNode, LazyStrapiBlocksTextParagraphNode, LazyStrapiBlocksTextUnorderedListNode, LazyComponentBlockEmbedVideo, LazyComponentBlockGallery, LazyComponentBlockMediaDouble, LazyComponentBlockMediaSingle, LazyComponentBlockTextAndMedia, LazyStrapiBlocksTextCodeNode, LazyStrapiBlocksTextImageNode, LazyStrapiBlocksTextQuoteNode, LazyStrapiBlocksTextBoldInlineNode, LazyStrapiBlocksTextCodeInlineNode, LazyStrapiBlocksTextItalicInlineNode, LazyStrapiBlocksTextLinkInlineNode, LazyStrapiBlocksTextStrikethroughInlineNode, LazyStrapiBlocksTextUnderlineInlineNode, LazyStrapiBlocksText, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["StrapiBlocksTextHeading1Node", LazyStrapiBlocksTextHeading1Node],
["StrapiBlocksTextHeading2Node", LazyStrapiBlocksTextHeading2Node],
["StrapiBlocksTextHeading3Node", LazyStrapiBlocksTextHeading3Node],
["StrapiBlocksTextHeading4Node", LazyStrapiBlocksTextHeading4Node],
["StrapiBlocksTextHeading5Node", LazyStrapiBlocksTextHeading5Node],
["StrapiBlocksTextHeading6Node", LazyStrapiBlocksTextHeading6Node],
["StrapiBlocksTextListItemInlineNode", LazyStrapiBlocksTextListItemInlineNode],
["StrapiBlocksTextOrderedListNode", LazyStrapiBlocksTextOrderedListNode],
["StrapiBlocksTextParagraphNode", LazyStrapiBlocksTextParagraphNode],
["StrapiBlocksTextUnorderedListNode", LazyStrapiBlocksTextUnorderedListNode],
["ComponentBlockEmbedVideo", LazyComponentBlockEmbedVideo],
["ComponentBlockGallery", LazyComponentBlockGallery],
["ComponentBlockMediaDouble", LazyComponentBlockMediaDouble],
["ComponentBlockMediaSingle", LazyComponentBlockMediaSingle],
["ComponentBlockTextAndMedia", LazyComponentBlockTextAndMedia],
["StrapiBlocksTextCodeNode", LazyStrapiBlocksTextCodeNode],
["StrapiBlocksTextImageNode", LazyStrapiBlocksTextImageNode],
["StrapiBlocksTextQuoteNode", LazyStrapiBlocksTextQuoteNode],
["StrapiBlocksTextBoldInlineNode", LazyStrapiBlocksTextBoldInlineNode],
["StrapiBlocksTextCodeInlineNode", LazyStrapiBlocksTextCodeInlineNode],
["StrapiBlocksTextItalicInlineNode", LazyStrapiBlocksTextItalicInlineNode],
["StrapiBlocksTextLinkInlineNode", LazyStrapiBlocksTextLinkInlineNode],
["StrapiBlocksTextStrikethroughInlineNode", LazyStrapiBlocksTextStrikethroughInlineNode],
["StrapiBlocksTextUnderlineInlineNode", LazyStrapiBlocksTextUnderlineInlineNode],
["StrapiBlocksText", LazyStrapiBlocksText],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
